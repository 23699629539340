





































import Vue from 'vue';
import Component from 'vue-class-component';
import ConfigSelector from '@/apps/data/components/ConfigSelector.vue';
import SearchField from '@/components/common/lists/SearchField.vue';
import { CustomAction, SearchFieldProps } from '@/components/common/interfaces';
import ParticipantStateList from '@/apps/study/components/ParticipantStateList.vue';
import DataLabelList from '@/apps/study/components/DataLabelList.vue';
import { Participant } from '@/models/study/models';
import { ToastProgrammatic as Toast } from 'buefy';
import { globalStore } from '@/store/modules/global';
import { BaseListColumn, ListModelField } from '@/models/core/base';
import { Filter } from '@/api/ApiClientV2';
import { StudyConfig } from '../interfaces';

@Component({
  components: {
    ConfigSelector,
    SearchField,
    ParticipantStateList,
    DataLabelList,
  },
})
export default class ParticipantList extends Vue {
  Participant = Participant;
  loaded = false;
  listFields: ListModelField[] = Participant.listFields;
  searchFields: SearchFieldProps[] = [];

  get columns(): BaseListColumn[] {
    return Participant.defaultColumns(Participant.langPath, this.listFields);
  }

  async created(): Promise<void> {
    const setting = globalStore.clientAppSetting('config');
    if (!setting) {
      Toast.open({
        message: this.$tc('client.settingGetFailure', 1, { key: 'config' }),
        type: 'is-danger',
        duration: 5000,
      });
    }
    const studyConfig: StudyConfig | undefined = setting?.value;
    this.listFields =
      studyConfig?.participant_list_fields || Participant.listFields;
    this.searchFields = setting?.value?.participant_list_search_fields || [];
    // get state from url query
    let state: string | undefined = this.$routerHandler.query('')['state'];
    // if no state, get default from client app settings
    if (!state) {
      const loading = this.$buefy.loading.open({});
      try {
        state = studyConfig?.participant_list_default_state || undefined;
        await this.$routerHandler.updateQuery('', {
          state,
        });
      } catch (error) {
        this.$errorHandler.handleError(error);
      }
      loading.close();
    }
    this.loaded = true;
  }

  get filter(): Filter {
    return {
      // explicitly set to 'unknown' to not show anything
      application: globalStore.selection.application?.id ?? 'unknown',
    };
  }

  customActions(): CustomAction[] {
    return [
      {
        label: this.$tc('common.delete'),
        icon: 'mdi-close',
        type: 'is-danger',
        disabled: (participant: Participant) => {
          return !this.canDeleteParticipant(participant);
        },
        callback: async (participant: Participant) => {
          this.confirmDelete(participant);
        },
      },
    ];
  }

  canDeleteParticipant(participant: Participant): boolean {
    const studyConfig: StudyConfig | undefined = globalStore.clientAppSetting(
      'config',
    )?.value;
    if (studyConfig?.participant_states_allow_deletion === undefined) {
      return true;
    } else {
      if (participant.state === undefined) {
        return true;
      } else {
        return studyConfig.participant_states_allow_deletion.includes(
          participant.state,
        );
      }
    }
  }

  confirmDelete(participant: Participant): void {
    this.$buefy.dialog.confirm({
      message: this.$tc('common.confirmDelete'),
      onConfirm: async () => {
        await this.delete(participant);
      },
    });
  }

  async delete(participant: Participant): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      await this.$apiv2.delete(Participant, participant.id);
      this.$buefy.toast.open({
        message: this.$tc('common.deleteSuccess'),
        type: 'is-success',
      });
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }
}
